/* eslint-disable */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link, Typography, VariableContent } from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'
import header from '../components/Header'
import footer from '../components/Footer'
import Footer from '../components/Footer'
import footerData from '../data/footerData'
import headerData from '../data/headerData'

const Home = () => {
  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          canonical: 'https://clearlink.com/404',
          robots: 'follow,index',
        },
        path: '/404',
        hideStickyCTA: false,
        spanish: false,
        sections: [],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlink.com',
          siteName: 'Clearlink',
          alternateName: 'Clearlink',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Clearlink | About',
    defaultRobots: 'follow,index',
    header: header(),
    footer: footer(),
    main: (
      <>
        <VariableContent
          mainContent={
            <>
              <Typography variant="h1">
                OOPS!
                <br /> SOMETHING WENT WRONG
              </Typography>
              <Typography style={{ marginBottom: '26px' }}>
                The page you're looking for can't be found. Please check your
                URL or return to the <Link to="/">home page.</Link>
              </Typography>
            </>
          }
          alignMainContent="center"
        ></VariableContent>
      </>
    ),
    disableBreadcrumbs: false,
  }

  return <Layout {...layoutProps} />
}
export default Home
